:root {
  --primary: #0fa7cd;
  --secondary: #6c757d;
  --success: #28a745;
  --danger: #dc3545;
  --warning: #ffc107;
  --info: #17a2b8;
  --white: #fff;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

th {
  font-weight: bold;
  background-color: #0fa7cd;
}

.caseNbr {
  font-family: sans-serif;
  margin: auto;
  width: 300;
}

.paper {
  width: 80%;
  padding: 10px;
  margin: auto;
}

.caseTable {
  width: 75%;
  margin: auto;
}

.headerDiv {
  background-color: var(--primary);
  color: var(--white);
}

.centerText {
  text-align: center;
}

.logo {
  display: block;
}

.form-group TextareaAutosize.form-control{
  height: auto;
}

label {
  float: left;
  width: 10em 100% !important;
  margin-right: 1em;
  font-weight:bold;
}

.rowC{display:flex; flex-direction:row;}

.float-container {
  border: 3px solid #fff;
  padding: 5px;
}

.float-child {
  width: 50%;
  float: left;
}  

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2px;
}

.container {
  display: inline-block;
}


.MuiStepper-root {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  -webkit-flex-direction: row; 
  -ms-flex-direction: row;
  flex-direction: row; 
  -webkit-align-items: center; 
  -webkit-box-align: center; 
  -ms-flex-align: center;
  align-items: center;
}

.MuiStepIcon-active { color: "red" }
.MuiStepIcon-completed { color: "green" }
.Mui-disabled .MuiStepIcon-root { color: "cyan" }

.mobile-title{
  display: none;
}


/* Responsive layout - makes a one column-layout */
@media (max-width: 800px) {
  .MuiStepper-root {
    display: none !important;
  }
  .mobile-title{
    display: inline;
  }
}

.formGroup {
  align-items: 'center' !important;
  width: 40% !important;
  margin: auto;
}

.button {
  background-color: var(--primary);
  color: var(--white);
  padding: 7px 14px;
  margin: 0 5px;
  border-radius: 5px;
  border: 1px solid transparent;
}

.buttonSecondary {
  background-color: var(--secondary);
  color: var(--white);
  padding: 7px 14px;
  margin: 0 5px;
  border-radius: 5px;
  border: 1px solid transparent;
}

.yellowButton {
		font-size:16px !important;
		font-weight:500 !important;
		color: #515151 !important;
		border-radius: 30px !important;
    background-color: #f8de7e !important; 
 }

 .disabledButton {
  font-size:16px !important;
  font-weight:500 !important;
  color: #515151 !important;
  border-radius: 30px !important;
  background-color: #D3D3D3 !important; 
}

.greyButton {
  font-size:16px !important;
  font-weight:500 !important;
  background-color: var(--secondary) !important;
  color: var(--white) !important;
  border-radius: 30px !important;
}

.redButton {
    font-size:16px;
    font-weight:500;
    color: #FFFFFF;
    border-radius: 30px;
    background-color: #f9c700 !important;
  }


/*
.login-form {
  display: inline-block;
  width: 20em;
}

.full-width-input {
  display: block;
  text-align: right;
}

.full-width-input input {
  width: 12em;
  padding: 4px 8px;
  margin: 4px 8px;
  border: 1px solid #aaaaaa;
  border-radius: 0;
}*/

.modal-size {
  position: absolute;
  top: 10%;
  left: 10%;
  overflow: scroll;
  height: 100%;
  display: block;
}